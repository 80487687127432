










import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { LikesViewModel } from '../../../viewmodels/likes-viewmodel'

@Observer
@Component({
  components: {
    'post-list-item': () => import('@/modules/community/common/components/post-list-item.vue'),
    observer: () => import('vue-intersection-observer'),
  },
})
export default class PostLists extends Vue {
  @Inject() vm!: LikesViewModel
  loadMore() {
    if (this.vm.canLoadMoreLikedPost) {
      this.vm.loadMoreLikedPost()
    }
  }
}
